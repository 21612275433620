:root {
  --blue: #8AAAE5;
}

@font-face {
  font-family: "Sacramento";
  src: url("styles/fonts/Sacramento/Sacramento-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  src: url("styles/fonts/Lato/Lato-Bold.ttf") format("truetype"), url("styles/fonts/Lato/Lato-Light.ttf") format("truetype"), url("styles/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic, oblique;
}

@font-face {
  font-family: "LatoBold";
  src: url("styles/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LatoRegular";
  src: url("styles/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LatoLight";
  src: url("styles/fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.App-header {
  font-family: "Sacramento";
  text-align: center;
  color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}

.App-header h1 {
  font-size: 3.5rem;
  margin: 0;
}

.App-body {
  font-family: "LatoRegular";
  line-height: 1.4em;
  padding-bottom: 1rem;
}

.App-body h1 {
  font-family: "Sacramento";
  padding: 1rem 0 0.5rem 0;
  margin: 0;
}